.extra-tags-modal {
  position: absolute;
  display: flex;
  background: white;
  flex-direction: column;
  margin-left: 5px;
  z-index: 500;
  transform: translate(-15px, 20px);
  padding: 14px 9px 7px 9px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px 0px #92929240;
  border: 1px solid #e4e5e9;
  width: 150px;
  .ch-tag {
    margin-bottom: 7px;
    margin-left: 0;
    width: max-content;
  }
}
