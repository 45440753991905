@import url("https://fonts.cdnfonts.com/css/segoe-ui-4");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #fafafa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#sdkteams {
  position: fixed;
  display: inline-block;
  z-index: 9999;
  border: 1px solid rgb(156, 163, 172);
  border-radius: 10px;
  max-height: 100%;
  opacity: 1;
  bottom: 33px;
  left: 15px;
  height: 540px;
  width: 35em;
  max-width: 100%;
  max-height: 100%;
}

@media only screen and (max-width: 480px) {
  #sdkteams {
    width: 90%;
    height: 300px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  #sdkteams {
    width: 70%;
    height: 500px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  #sdkteams {
    width: 60%;
    height: 600px;
  }
}

@media only screen and (min-width: 1025px) {
  #sdkteams {
    width: 40%;
    height: 700px;
  }
}

.fullscreen-page {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  background-color: #fafafa !important;
  height: 100vh !important;
  overflow: hidden !important;
  flex-wrap: nowrap !important;
  width: calc(100% - 45em); /* Largeur totale moins la largeur du SDK Teams */
  margin-left: 25em; /* Laisser de l'espace pour le SDK Teams */
}

.content-section {
  margin-left: 0.7rem !important;
  margin-bottom: 0.7rem !important;
  flex: 1 !important;
  max-width: 1100px !important;
  max-height: 90vh !important;
  overflow-y: auto !important;
}

.dial-section {
  margin-right: 0.7rem !important;
  margin-bottom: 0.7rem !important;
  height: 90vh !important;
}
/* @media screen and (max-width: 1280px) and (max-height: 720px) {
    .content-section {
      margin-left: 25% !important;
    }
  } */

@media only screen and (max-width: 1440px) {
  .fullscreen-page {
    width: calc(100% - 45em) !important;
    margin-left: 25em !important;
  }
}

@media only screen and (max-width: 1280px) {
  .fullscreen-page {
    width: calc(100% - 40em) !important;
    margin-left: 20em !important;
  }
}

@media only screen and (max-width: 1024px) {
  .fullscreen-page {
    width: calc(100% - 35em) !important;
    margin-left: 15em !important;
  }
}

@media only screen and (max-width: 768px) {
  .fullscreen-page {
    width: calc(100% - 26em) !important;
    margin-left: 6em !important;
  }
}

@media only screen and (max-width: 480px) {
  .fullscreen-page {
    width: calc(100% - 20em) !important;
    margin-left: 0 !important;
  }
}

@media screen and (max-width: 1280px) and (max-height: 720px) {
  /* Règles CSS pour les écrans avec une résolution de 1280x720 pixels ou moins */
  .fullscreen-page {
    width: calc(100% - 22em) !important;
    margin-left: 22em !important;
  }
}
