/* @import url('https://fonts.cdnfonts.com/css/segoe-ui-4'); */
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --color-text: black;
  --color-text-secondary: #464e5f; /* #2d5380 */
  --color-primary: #40e0cf;
  --color-secondary: #2d5380;

  /* gradients */

  --primary-gradient: linear-gradient(
    90deg,
    rgba(64, 224, 207, 1) 0%,
    rgba(54, 205, 207, 1) 100%
  );

  --color-gray: #c3c3c3;

  /* for badges */

  --color-success: #61e786;
  --color-failure: #f47266;

  --color-pending: #ffdd86;

  --color-progress: #95a8ff;
  /* font-family: 'Segoe UI'; */
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Segoe UI", "sans-serif";
}

p {
  margin: 0 !important;
  color: var(--color-text-secondary);
}
/* specify other tags aswell */

a {
  text-decoration: none;
  color: var(--color-text);
}
h5,
h6 {
  margin: 0 !important;
}
.cursor-pointer {
  cursor: pointer;
}
@font-face {
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Regular"),
    url("../Assets/Font/segoe-ui/Segoe\ UI.woff") format("woff");
}

/* @font-face {
  font-family: 'Segoe UI Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Segoe UI Italic'), url('../Assets/Font/segoe-ui/Segoe\ UI\ Italic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Segoe UI Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Segoe UI Bold'), url('Se') format('woff');
  }
  

  @font-face {
  font-family: 'Segoe UI Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Segoe UI Bold Italic'), url('Segoe UI Bold Italic.woff') format('woff');
  } */
