/* @import url('https://fonts.cdnfonts.com/css/segoe-ui-4'); */
.fullscreen-contact-details {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #fafafa;
  overflow: hidden;
  border: 1px solid red;
}
.dialer-contact-details {
  width: 23vw;
  /* background-color: #FAFAFA; */
  align-self: center;
  display: flex;
  padding: 20px;
}
.card-field-contact-details {
  /* width: 37vw; */
  padding-top: 20px;
  padding-left: 5px;
  /* background-color: red; */
}
.card-field-header {
  /* position: absolute; */
  height: 72px;
  left: 456px;
  padding-top: 2px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  /* padding: 10px; */
  background: #ffffff;
  border-radius: 6px;
  /* font */
}
.card-font-header {
  width: 100%;
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  padding: 10px;
  /* padding-bottom: 20px; */

  color: #404040;
}
.card-inital-header {
  position: relative;
  border: 1px solid #2d5380;
  justify-content: center;
  align-items: center;
  align-self: center;
  display: flex;
  min-width: 30px;
  min-height: 30px;
  padding: 4px;
  font-size: 13px;
  border-radius: 50%;

  /* margin-bottom: -10px; */
}
h5,
h6 {
  margin: 0 !important;
}

/* contact card */

.contact-card-box {
  width: 30vw;
  height: 280px;
  padding-left: 8px;
  padding-top: 2px;
  margin: 10px;
  display: flex;
  background: #ffffff;
  border-radius: 8px;
  margin-top: 20px;
  /* padding: 20px; */
}
.contact-card-box-header {
  /* position: absolute; */

  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  padding: 10px;
  display: flex;
  /* padding-top: 10px; */
  /* identical to box height */
  /* background-color: red; */

  /* Secondary */

  color: #2d5380;
}
.contact-card-box-phone-number {
  padding-left: 8px;
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  /* display: flex; */
  /* width: 40vw; */
  background-color: #fff;
  /* identical to box height */

  /* Shade 2 */

  color: #595959;
}
.call-icon {
  /* background-color: aqua; */
  justify-content: right;
  /* margin-left: 38%; */
  height: auto;
  width: 25px;
  margin-right: 25.67px;
}
.briefcase-icon {
  /* background-color: aqua; */
  justify-content: right;
  /* margin-left: 38%; */
  height: auto;
  width: 18px;
  margin-right: 28px;
  margin-bottom: 4px;
}
.company-title {
  padding-left: 8px;
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;

  display: flex;
  width: 100%;
  margin-bottom: 4px;
  background-color: #fff;
  /* identical to box height */

  /* Shade 2 */

  color: #595959;
}
.call-button {
  background-color: none;
  border: none;
  /* justify-content: right; */
  margin-left: 38%;
}
.message-icon {
  justify-content: right;
  margin-left: 4%;
  height: auto;
  width: 30px;
}
.message-box {
  background-color: aqua;
  height: 30px;
  width: 50px;
  z-index: 50px;
  right: -300;
  /* box-shadow: ; */
  position: absolute;
}
.overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
.sms-box {
  width: 376px;
  height: 110px;
  /* left: 816px;
top: 253px; */
  /* background-color: aqua; */
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 4px;
}
.sms-box-text-area {
  border: none;
  min-width: 250px;
  display: flex;
  height: 100%;
  font-family: "Segoe UI Regular";
}
.sms-box-text-area:disabled {
  background-color: transparent;
}
.sms-box-text-area::placeholder {
  color: #a1a1a1;
}
.sms-box-text-area:focus {
  outline: none;
}
.sms-box-button-area {
  /* width: 376px;
height: 33px; */
  /* left: 0px;
top: 77px; */
  flex-direction: row;
  display: flex;
  background: #f7f7f7;
  background-color: #f7f7f7 !important;
}
.sms-box-header-text {
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  /* Secondary */

  color: #2d5380;
}
.sms-box-dropdown {
  /* background-color: #39D3D0; */
  width: 48vw;
  /* height: 100%; */
  border: none;
  color: #39d3d0;
  padding: 2px;
}
.sms-box-button-send {
  display: flex;
  justify-content: right;
  align-items: center;
  padding-right: 30px;
  /* background-color: red; */
  width: 100%;
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
  /* identical to box height */

  color: #39d3d0;
}
.contact-sub-box {
  /* width: 40vw; */
  /* background-color: #FFFFFF; */
  height: 193px;
  min-width: 250px;
  padding: 10px 15px;
  padding-right: 0px;
  overflow: hidden;
  background: #ffffff;
  border-radius: 6px;
}
.status-text {
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  justify-content: right;
  padding: 10px;
  /* identical to box height */

  color: #128c7e;
}
.cursor-pointer {
  cursor: pointer;
}
::placeholder {
  padding: 5px;
}
@font-face {
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Regular"),
    url("../../Assets/Font/segoe-ui/Segoe\ UI.woff") format("woff");
}
.lft-box {
  display: block;
}

.right-box {
  display: block;
  margin-left: 12px;
  flex: 1;
  background: #fefefe;
  border-radius: 6px;
  min-width: 500px;
  margin-bottom: 50px;
}
.disabled {
  pointer-events: none;
}
.main-box-contact-details {
  display: flex;
  /* width: 66vw; */
  margin-top: 12px;
  margin-bottom: 1rem;
}
.callhistory-contactdetails {
  display: flex;
  display: block;
  /* background-color: #FFFFFF; */
  margin-top: 14px;
  /* overflow: hidden; */
}

.callhistory-contactdetails-sub::-webkit-scrollbar {
  display: none;
}

.callhistory-contactdetails-header {
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  padding-left: 10px;
  line-height: 19px;
  /* identical to box height */

  /* Secondary */

  color: #2d5380;
}
.callhistory-contactdetails-sub {
  height: 285px;
  overflow-y: scroll;
}

.contact-details-inital-font {
  font-family: "Segoe UI Regular";

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height */

  text-align: center;

  /* Secondary */

  color: #2d5380;
}

.main-box-contact-details {
  overflow: auto;
}

/**/
@import url("https://fonts.cdnfonts.com/css/segoe-ui-4");
.contact-details-container {
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
}
.contact-details-container .fullscreen-contact-details {
  font-family: Segoe UI;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  background-color: #fafafa;
}
.contact-details-container .fullscreen-contact-details .dial-section {
  margin-right: 2rem;
  justify-content: center;
  align-self: center;
  height: 100%;
  display: flex;
  margin-top: 1.3rem;
}
.contact-details-container .card-field-contact-details {
  margin-right: 0.7rem;
  min-width: 70vw;
  max-width: 70vw;
  height: 90vh;
  max-height: 90vh;
}

.details-loader {
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-profile-image-box {
  transform: translateY(4px);
}

.back {
  display: inline-block;
  height: 60px;
  cursor: pointer;
  color: #2d5380;
  margin-left: -10px;
  display: flex;
  align-items: center;
  justify-self: center;
}
.back svg {
  width: 18px;
  transform: rotate(180deg) translateX(-6px);
  height: 18px;
  margin-right: 14px;
  margin-left: 1px;
}
.userHistory {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 450px;
  align-items: center;
  /* background-color: #f7f7f7; */
  padding: 10px;
}

@media screen and (max-width: 700px) {
  .main-box-contact-details {
    flex-direction: column;
    gap: 2rem;
    overflow: hidden;
  }
  .right-box {
    margin-left: 0;
    margin-bottom: 5rem;
    min-width: unset;
  }
  .send-to-option {
    font-size: 0.75rem !important;
    transform: translateY(3px);
  }
  .smsFrom-1 {
    font-size: 0.75rem !important;
    transform: translateY(3px);
  }
}
