.ringover-call-iframe {
  position: fixed;
  bottom: 3px;
  left: 15px;
  z-index: 9000;

  .call-btn {
    padding: 0;
    height: 30px;
    width: 30px;
    background-color: transparent;
    border: none;
    border-radius: 50%;
    background: #37cdd0;
    outline: none;
    svg {
      color: #fff;
      width: 21px;
      height: 21px;
      transform: translate(0px, -1px);
      &.d-s {
        width: 17px;
        height: 17px;
      }
    }
  }
  .call-iframe {
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    position: fixed;
    bottom: 55px;
    left: 65px;
    transform: scale(0.95);
    iframe {
      width: 400px;
     
    }
    @media screen and (max-height: 720px) {
      bottom: 15px;
      left: 50px;
      transform: scale(0.85);
    }
    @media screen and (max-height: 600px) {
      bottom: -14px;
      left: 20px;
      transform: scale(0.75, 0.75);
    }
    @media screen and (max-height: 500px) {
      bottom: -50px;
      left: 10px;
      transform: scale(0.65, 0.65);
    }
    @media screen and (min-height: 1080px) {
      bottom: 90px;
      left: 90px;
      transform: scale(1.05);
    }
  }
}

.ringover-call-iframe-zoom {
  position: fixed;
  bottom: 3px;
  left: 15px;
  z-index: 9000;

  .call-btn {
    padding: 0;
    height: 31px;
    width: 31px;
    background-color: transparent;
    border: none;
    border-radius: 50%;
    background: #37cdd0;
    outline: none;
    svg {
      color: #fff;
      width: 21px;
      height: 21px;
      transform: translate(0px, -1px);
      &.d-s {
        width: 17px;
        height: 17px;
      }
    }
  }
  .call-iframe {
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    position: fixed;
    bottom: 55px;
    left: 65px;
    transform: scale(0.95);
    iframe {
      width: 400px;
     
    }
    @media screen and (max-height: 720px) {
      bottom: 15px;
      left: 50px;
      transform: scale(0.85);
    }
    @media screen and (max-height: 600px) {
      bottom: -14px;
      left: 20px;
      transform: scale(0.75, 0.75);
    }
    @media screen and (max-height: 500px) {
      bottom: -50px;
      left: 10px;
      transform: scale(0.65, 0.65);
    }
    @media screen and (min-height: 1080px) {
      bottom: 90px;
      left: 90px;
      transform: scale(1.05);
    }
  }
}
// iframe {
//   @media screen and (min-height: 500px) and (max-height: 720px) {
//     bottom: 35px !important;
//     left: 10px !important;
//   }
//   @media screen and (max-height: 600px) {
//     bottom: 24px !important;
//     left: -20px !important;
//   }
//   @media screen and (max-height: 500px) {
//     bottom: -70px !important;
//     left: -30px !important;
//     transform: scale(0.65, 0.65);
//   }
//   @media screen and (min-height: 1080px) {
//     bottom: 90px !important;
//     left: 90px !important;
//     transform: scale(1.05);
//   }
// }
