@import url("../../Styles/style.css");

.custom-btn {
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  height: 40px;
  /* width: 172px; */
  font-weight: 500;
  border-radius: 0.5rem;
  padding: 1rem 1.25rem;
  cursor: pointer;
  font-family: "Work Sans", "sans-serif";
}

.btn-fill {
  background: var(--secondary-gradient);
  color: #ffffff;
}

.btn-fill:hover {
  cursor: pointer;
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.35);
}

.btn-outline,
.btn-outline::after {
  background: #ffffff;
  border: 1.5px solid var(--color-secondary);
  color: var(--color-secondary);
}
.btn-outline:hover {
  cursor: pointer;
  background: var(--secondary-gradient);
  border: 1.5px solid var(--color-secondary);
  color: #ffffff;
}

.disabled {
  opacity: 0.25;
}

.lt {
  font-weight: 500;
  color: #37cdd0;

  /* font-family: 'Roboto', 'sans-serif'; */
}

.btn-fill-primary {
  background: var(--primary-gradient);
  color: #ffffff;
}
