@import url("../../Styles/style.css");

.outer-crd {
  flex-direction: row;
  display: flex;
  width: 100%;
  /* order: 4;  */
  height: 64px;
  position: relative;
  /* margin: 8px 0px; */
  /* flex: none; */
  /* padding-left: 10px; */
  /* padding-top: 10px; */
  /* margin-top: 8px; */
  background-color: #ffffff;
  border-radius: 6px;
}
.inner-crd-callhistory {
  width: 30%;
  min-width: 150px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 25px;
  /* background-color: aqua; */
}
.inner-crd-time {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: right;
  padding-right: 20px;
  font-family: "Work Sans", sans-serif;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  text-align: right;

  color: #939393;
  position: relative;

  /* background-color: aqua; */
}
.inner-crdd {
  /* flex:1; */
  flex-direction: row;
  flex: 1;
  padding: 0 10px;
  display: flex;
  align-items: center;

  /* background-color: aqua; */
}
.section-header {
  display: flex;
  flex-direction: column;
  width: "100%";
  height: "100%";
}

.section-datalist {
  display: flex;
  flex-direction: column;
}

.call-wrapper {
  display: flex;
  width: "100%";
  padding: 2px;
  display: flex;
  margin-top: 8px;
  /* background-color: red; */
}

.box-border-call-history {
  font-size: 12px;
  position: relative;
  border: 2px solid var(--color-secondary);
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;

  height: 34px;
  width: 34px;
  color: var(--color-secondary);
  display: flex;
  border-radius: 25px;

  /* margin-bottom: -20px; */
}
.padding-left {
  padding-left: 10px;
}
.padding-right {
  padding-right: 10px;
}
.padding-bottom {
  padding-bottom: 4px;
}
/* .outer-crd:hover{
    border: 1px solid #000000;
} */
.text-right {
  text-align: right;
}
.call-section-header {
  width: 100%;
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;

  color: #404040;

  background-color: #fafafa;
  padding: 16px;
}
.call-section-initial {
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height */

  text-align: center;

  /* Secondary */

  color: #2d5380;
}
.call-section-card {
  padding-right: 20px;
}
.number-text {
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height */

  /* Shade 3 */

  color: #939393;
}
.title-text {
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  padding-bottom: 3px;
  /* identical to box height */
  color: #404040;
}
.title-text-duration{
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  padding-bottom: 5px;
  /* identical to box height */
  color: #404040;
}
.title-text h6 {
  width: 100px;
}

.call-icon-callhistory {
  margin-right: 8px;
  cursor: pointer;
}
.sms-icon-callhistory {
  margin-right: 34px;
  cursor: pointer;
}
@font-face {
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Regular"),
    url("../../Assets/Font/segoe-ui/Segoe\ UI.woff") format("woff");
}

.call-histories {
  overflow-y: auto;
  max-height: 90vh;
  padding-bottom: 40px;
}
/* .call-histories::-webkit-scrollbar {
  width: 0;
  display: none;
} */
.call-notes-tags {
  margin-left: 10px;
  display: flex;
  align-items: center;
  height: 100%;
}
.ch-notes-svg {
  cursor: pointer;
  transform: translateY(-4px);
}
.ch-tags {
  display: flex;
  overflow-x: auto;
  max-width: 200px;
  margin-left: 5px;
  height: 100%;
}
.ch-tag {
  margin-right: 3px;
  height: 20px;
  align-self: center;
  transform: translateY(-2px);
  margin-left: 3px;
  min-width: max-content;
  display: inline-block;
  padding: 2px 4px;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  cursor: default;
}
.more-ch-tags {
  cursor: pointer;
}

@media screen and (max-width: 700px) {
  .title-text h6 {
    display: none !important;
    border: 1px solid red;
  }
  .outer-crd {
    width: unset;
    width: 100vw;
    min-width: 390px;
  }
  /* .inner-crd-time.text-right svg {
    width: 30px;
    height: 30px;
  } */
  h6 {
    font-size: 0.8rem;
  }
  .title-text {
    font-size: 0.8rem !important;
  }
  .number-text {
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 500px) {
  .call-notes-tags {
    display: none;
  }
  .inner-crdd {
    margin-right: 1rem;
  }
}
