/* @import url('https://fonts.cdnfonts.com/css/segoe-ui-4'); */
/* @import url('http://fonts.cdnfonts.com/css/segoe-ui'); */
@import url("../../Styles/style.css");

.login-page {
  /* justify-content: center;
    align-items: center; */
  display: flex;
  flex-direction: row;
  height: 90vh;
}
.logo {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.input-field {
  height: 100vh;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
#login-form {
  width: 100%;
}

.if-2 {
  width: 100%;
}
.if-2 #login-form {
  width: 50%;
  min-width: 300px;
}

.login-page .input-field input {
  font-family: "Segoe UI Regular", Tahoma, Geneva, Verdana, sans-serif;
  /* color: #a1a1a1; */
  font-weight: 400;
}
.login-page .input-field input::placeholder {
  color: #a1a1a1;
}

.login-form-wrapper {
  display: flex;
  flex-direction: column;
}
.button {
  padding-top: 35px;
  align-items: center;
  justify-content: center;
}
.text-heading {
  align-items: left;
  justify-content: left;
  font-weight: 400;
  font-size: 24px;
  line-height: 20px;
  text-align: left;
  padding-bottom: 32px;
  font-family: "Segoe UI Regular";
}
.text-heading2 {
  align-items: left;
  justify-content: left;
  font-weight: 400;
  font-size: 14px;
  line-height: 18.62px;
  text-align: left;
  padding-bottom: 10px;
  font-family: "Segoe UI Regular";
}
@font-face {
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Regular"),
    url("../../Assets/Font/segoe-ui/Segoe\ UI.woff") format("woff");
}
/* ::placeholder{
    padding: 100px;
} */

@media screen and (max-width: 800px) {
  .login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .input-field {
    display: flex;
    align-items: flex-start;
    justify-content: start;
    width: max-content;
  }
  .input-style {
    width: 80vw;
  }
  .if-2 #login-form {
    min-width: unset;
  }
}
