.call-via-frame {
  float: left;
  cursor: pointer;
}
.dialer.classic {
  &::-webkit-scrollbar {
    display: none;
    width: 0;
  }
}
