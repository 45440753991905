.cim-modal {
  width: 250px;
  // height: 150px;
  transform: scale(1.1);
  position: absolute;
  top: 65px;
  left: 70px;
  z-index: 10;
  border-radius: 10px;
  font-size: 12px;
  text-align: start;
  background-color: #fff;
  box-shadow: 0px 4px 10px 0px #c2c2c240;
  border: 1px solid #bfbfbf;
  padding: 14px 12px;
  .cim-header {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
    color: #404040;
    display: flex;
    align-items: center;

    .cim-user-profile-image-box {
      margin-right: 10px;
      position: relative;
      border: 1px solid #2d5380;
      justify-content: center;
      align-items: center;
      align-self: flex-start;
      height: 34px;
      width: 34px;
      display: flex;
      border-radius: 25px;
      display: inline-block;
      margin-top: 2px;
      .cim-user-profile-image {
        height: 34px;
        width: 34px;
        border-radius: 25px;
        transform: translate(-1px, -1px);
      }
      .box-initial {
        font-size: 16px;
        display: flex;
        justify-content: center;
        margin-top: 7px;
      }
    }
  }
  .cim-phone {
    color: #acacac;
    margin-left: 2px;
    margin-top: 7px;
  }
  .msg-warning {
    font-family: Segoe UI;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: #ff9b4a;
    margin-top: 6px;
    margin-bottom: 6px;
  }
  .cim-input {
    margin-top: 6px;
    margin-bottom: 1.2rem;
    position: relative;
    overflow: hidden;
    height: 70px;
    padding-bottom: 0;
    border: 0.78px solid #cccccc;
    border-radius: 8px;
    textarea {
      width: 100%;
      padding: 8px 6px;
      padding-bottom: 20px;
      border: none;
      outline: none;
      height: 50px;
      padding-right: 25px;
      resize: none;
    }
    textarea:focus {
      outline: none;
      padding-bottom: 20px;
    }
    textarea::placeholder {
      color: #acacac;
      width: 100%;
      padding: 0px 0px;
      padding-right: 25px;
    }
    .send-options {
      border-bottom-left-radius: 8px;
      border-left: 1px solid #cccccc;
      border-right: 1px solid #cccccc;
      border-bottom: 1px solid #cccccc;
      border-bottom-right-radius: 8px;
      position: absolute;
      bottom: -1px;
      left: -1px;
      background: #f7f7f7;
      width: 224px;
      z-index: 11;
      display: flex;
      justify-content: space-between;
      padding-right: 5px;
      padding-top: 3px;
      span {
        padding-left: 5px;
        select {
          background-color: transparent;
          border: none;
          color: #939393;
          cursor: pointer;
        }
        select:focus {
          outline: none;
        }
        option {
          background-color: #f7f7f7;
          color: #939393;
        }
      }
      svg {
        width: 13px;
        height: 13px;
        color: #939393;
        cursor: pointer;
      }
    }
  }
  .cim-cd-heading {
    color: #2d5380;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 8px;
  }
  .cim-cd {
    position: relative;
    display: flex;
    margin-bottom: 2px;
    justify-content: space-between;
    .custom-disabled {
      pointer-events: none;
    }
    span {
      flex: 0.9;
      padding: 2px 0px;
      cursor: pointer;
    }

    svg {
      padding: 4px 0px;
      width: 25px;
      height: 25px;
      cursor: pointer;
    }
  }
}
