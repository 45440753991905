@import url("../../Styles/style.css");
.outer-box-callhistory-contacts {
  height: 64px;

  background: #fefefe;
  border-radius: 6px;

  align-items: center;
  /* Inside Auto Layout */

  display: flex;
  order: 0;
  flex-grow: 0;
  margin: 8px 0px;
  padding-right: 12px;
}
.outer-box-callhistory-contacts-icon-div {
  justify-content: center;
  align-items: center;
}
.outer-box-callhistory-contacts-icon {
  height: 20px;
  justify-content: center;
  margin-left: 20px;
}
.box-callhistory-contacts-callType {
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  margin-left: 12px;
  /* identical to box height */

  /* Shade 1 */

  color: #404040;
}
.box-callhistory-contacts-callduration {
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  justify-content: center;
  /* line-height: 19px; */
  margin-left: 2px;
  /* identical to box height */
  color: #404040;
}
.box-callhistory-contacts-time {
  font-family: "Work Sans";
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  text-align: right;
  /* background-color: aqua; */

  color: #939393;
  display: flex;
  width: 30%;
  margin-left: 40px;
  justify-content: right;
}
@font-face {
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Regular"),
    url("../../Assets/Font/segoe-ui/Segoe\ UI.woff") format("woff");
}
