@import url("https://fonts.cdnfonts.com/css/segoe-ui-4");

.input-box {
  width: 100%;
  height: 32px;
  left: 798px;
  top: 413px;
  padding-left: 14px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 4px;
  color: #000000;
}

.search {
  width: 622px;
}

.search::placeholder {
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  padding-left: 4px;
  /* identical to box height */

  color: #a2a2a2;
}

.label-style {
  padding: 0.5rem 0;
  font-family: "Segoe UI Regular";
  font-weight: 500;
  align-self: flex-start;
}

.input-style {
  display: flex;
  /* background-color:'red'; */
  flex-direction: column;
  width: 100%;
}

.dialer {
  justify-content: center;
  align-items: center;
  border: none !important;
  border-color: #f9f9f9;
  background: #f9f9f9;
  border-radius: 6px;
}
@font-face {
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Regular"),
    url("../../Assets/Font/segoe-ui/Segoe\ UI.woff") format("woff");
}
