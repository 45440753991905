@import url("https://fonts.cdnfonts.com/css/segoe-ui-4");
.nav-list {
  text-decoration: none;
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
  color: #6264a7;
  font-size: 14px;
  line-height: 30px;
  font-family: "Segoe UI Regular";
}
.active.nav-list {
  background-color: #f5f5f5;
  /* border-bottom: #40e0cf 1px solid; */
}
.nav-bgrnd {
  background-color: #f5f5f5;
}
.nav-bottom-line {
  width: 100%;
  height: 1px;
  border: none;
  background-color: #e0e0e0;
  /* margin-bottom: 10px */
}
.sign-out-button {
  border: none;
  text-decoration: none;
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
  color: #6264a7;
  font-size: 14px;
  line-height: 30px;
  background-color: #f5f5f5;
  /* display: flex; */
  /* width: 100%; */
}
@font-face {
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Regular"),
    url("../../Assets/Font/segoe-ui/Segoe\ UI.woff") format("woff");
}
