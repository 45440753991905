.outer-crd-contact {
  flex-direction: row;
  display: flex;
  height: 64px;
  padding: 10px;
  margin-bottom: 0.6rem;
  border-radius: 6px;
  background: #fefefe;
  position: relative;
}
.inner-crd {
  flex: 1;
  flex-direction: row;
  /* padding-left: 72px; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  /* background-color: aqua; */
}
.inner-crd-center {
  flex: 1;
  flex-direction: row;
  /* padding-left: 72px; */
  /* display: flex; */
  align-items: center;
  justify-content: flex-start;
  margin-left: 10px !important;
  /* background-color: aqua; */
}
.inner-crd-left {
  flex: 0.4;
  cursor: pointer;
  /* width: 10%; */
  flex-direction: row;
  display: flex;
  align-items: center;
  min-width: 160px;
  justify-content: left;
  margin-left: 10px !important;
  /* background-color: aqua; */
}
.inner-crd-right {
  flex: 1;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;

  /* background-color: aqua; */
}
.img {
  height: 45%;
  width: auto;
}
.custom-profile {
  position: relative;
  border: 1px solid #2d5380;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  height: 34px;
  width: 34px;
  display: flex;
  border-radius: 25px;
  margin-top: 15px;
  margin-bottom: 10px;
}
.box-initial {
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height */

  text-align: center;

  /* Secondary */

  color: #2d5380;
}
.inner-crd-number {
  /* flex: 1; */
  height: 100%;
  flex-direction: row;
  /* padding-left: 72px; */
  display: flex;
  /* align-items: center; */
  justify-content: left;
  /* margin-left: 10px !important; */
  margin-right: 45px;
}
.padding-left {
  padding-left: 10px;
  flex: 1;
}
.padding-right {
  padding-right: 10px;
}
.padding-bottom {
  padding-bottom: 4px;
}
.margin-left {
  margin-left: 15px;
}
/* .outer-crd:hover{
    border: 1px solid #000000;
} */
.concat-name {
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */
  height: 100%;
  padding-top: 25px;
  color: #404040;
}
.num-number {
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px !important;
  /* identical to box height */

  color: #727272;
}
.num-number-secondary {
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  margin-left: 14px;
  /* identical to box height */

  /* Shade 4 */

  color: #bfbfbf;
}
.search-box {
  position: relative;
  width: 100%;
  overflow: hidden;
  right: -10px;
  padding-top: 20px;
  padding-left: 1px;
}
.search-box input {
  max-width: 500px;
  height: 40px;
}
.search-box input::placeholder {
  max-width: 500px;
  height: 40px;
  font-style: "Segoe UI Regular";
}
.search-box input:focus {
  outline: none;
}
.user-profile-image-box {
  position: relative;
  border: 1px solid #2d5380;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  height: 34px;
  width: 34px;
  display: flex;
  border-radius: 25px;
  margin-top: 13px;
  margin-bottom: 10px;
  cursor: pointer;
}
.user-profile-image {
  height: 34px;
  width: 34px;
  border-radius: 25px;
}
@font-face {
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Regular"),
    url("../../Assets/Font/segoe-ui/Segoe\ UI.woff") format("woff");
}

.outer-crd-contact {
  padding: 0;
  padding-left: 10px;
  border: 1px solid rgba(164, 164, 164, 0.17);
}

.contact-main-card {
  overflow-x: hidden;
  overflow-y: visible;
  padding: 0px 15px 20px 10px;
  margin-top: 20px;
  max-height: 70vh;
  min-height: 70vh;
}

.contact-main-card::-webkit-scrollbar {
  width: 0px;
  display: none;
}

.search-box {
  display: flex;
  justify-content: space-between;
}
.search-box .filter {
  margin-right: 30px;
  color: #595959;
  font-size: 1.1rem;
  cursor: pointer;
}
.search-box .filter svg {
  margin-bottom: 2px;
}
.contact-crd-call {
  text-align: end;
  height: 100%;
  cursor: pointer;
  float: left;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-crd-sms {
  display: flex;
  height: 100%;
  align-items: center;
  justify-items: center;
  position: relative;
}
.contact-crd-sms .quick-sms-modal {
  right: 70px;
  position: absolute;
}
.ringover-icon-dp {
  position: absolute;
  right: -7px;
  bottom: -9px;
  transform: scale(0.5);
  color: #40e0cf;
  background-color: white;
}
.contacts-section {
  padding-bottom: 15px;
}

.results-not-found {
  width: 100%;
  height: 40vh;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 20px;
}
.search-box form {
  width: 100%;
}

@media screen and (max-width: 600px) {
  .outer-crd-contact {
    position: relative;
  }
  .inner-crd-left {
    flex: 1;
  }
  .concat-name {
    transform: translateY(-10px);
    font-size: 0.8rem;
  }
  .inner-crd {
    position: absolute;
    left: 25px;
    font-size: 0.8rem;
    width: 100%;
    bottom: 5px;
  }
  .input-style {
    width: 80vw;
  }
}
