.ch-notes {
  position: absolute;
  padding: 14px 12px 14px 12px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px 0px #92929240;
  border: 1px solid #e4e5e9;
  background: white;
  margin-left: 5px;
  z-index: 500;
  max-width: 400px;
  font-size: 14px;
  font-family: "Segoe UI Regular";
  .notes-heading {
    margin-bottom: 5px;
    font-size: 20px;
  }
}
