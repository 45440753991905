.signout-container {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  .left,
  .right {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 32vh;
  }
  @media screen and (max-width: 500px) {
    .left,
    .right {
      margin-top: 0;
    }
  }

  .left {
    width: 100%;
  }
  .right {
    align-items: flex-start;

    gap: 1rem;
    h2 {
      font-weight: 400;
    }
    .text-description {
      margin-bottom: 2rem;
    }
    .buttons {
      display: flex;
      width: 80%;
      justify-content: center;
      .custom-btn {
        width: 200px;
        height: 46px;
        font-size: 14px;
      }
    }
  }
  @media screen and (max-width: 700px) {
    flex-direction: column;

    .left,
    .right {
      height: unset;
      flex: 1;
      margin-top: 0;
    }
    .left {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    .right {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: flex-start;
    }
  }
}
