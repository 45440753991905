.reveiver-textbox{
width: 224px;
height: auto;
background: #F6F6F6;
border-radius: 9.01655px;
align-items: center;
justify-content: center;
/* padding-bottom: 12.74px;
padding-right: 19.85px;
padding-left: 13px; */
padding-top: 8px;
font-family: 'Segoe UI Regular';
font-style: normal;
font-weight: normal;
font-size: 13px;
line-height: 17px;

color: #000000;

}
.sender-textbox{
width: 224px;
height: auto;
background: #B6DCFA;
border-radius: 9.01655px;

padding-top: 8px;
align-items: center;
justify-content: center;


}
.text-chat-inside{
    font-family: 'Segoe UI Regular';
font-style: normal;
font-weight: normal;
font-size: 13px;
margin-bottom: 12.74px;
margin-right: 19.85px;
margin-left: 13px;
line-height: 17px;
margin-left: 13px;
overflow-wrap: break-word;
color: #000000;

}
.text-chat-time{
    width: 100%;
    
    text-align: right;
    padding-right: 11px;
    padding-top: 8px;

    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;

    color: rgba(0, 0, 0, 0.4);


}
@font-face {
    font-family: 'Segoe UI Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI Regular'), url('../../Assets/Font/segoe-ui/Segoe\ UI.woff') format('woff');
    }