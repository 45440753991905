@import url("https://fonts.cdnfonts.com/css/segoe-ui-4");
.contact-container {
  // max-width: 100vw;
  // max-height: 100vh;
  // overflow: hidden;
  border: 1px solid red;
  .fullscreen-contact {
    font-family: Segoe UI;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    background-color: #fafafa;
    .dial-section {
      margin-right: 2rem;
      justify-content: center;
      align-self: center;
      height: 100%;
      display: flex;
      margin-top: 1.3rem;
    }
    .contact-section {
      margin-right: 0.7rem;
      min-width: 70vw;
      max-width: 70vw;
      height: 90vh;
      max-height: 90vh;
      &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
      }
    }
  }
}
