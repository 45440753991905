.chatbox {
  /* background-color: blue; */
  display: block;
}
.chatbox-header {
  display: flex;
  border-bottom: 0.784397px solid #e0e0e0;
  position: relative;
}
.chatbox-header-name {
  display: flex;
  padding-top: 14.12px;
  padding-bottom: 8px;
}
.chatbox-header-number-text {
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  /* Secondary */

  color: #2d5380;
}
.chatbox-header-call-button {
  display: flex;
  align-items: center;
  justify-content: right;
  margin-right: 21.62px;
}
.chatbox-message-history {
  min-height: 55vh;
  max-height: 55vh;
  overflow-y: scroll;
  margin-bottom: 13px;
}

.chatbox-message-history::-webkit-scrollbar {
  display: none;
}

.chatbox-footer {
  height: 40px;
  display: flex;
  margin-left: 26px;
  margin-right: 15px;
  margin-bottom: 15px;
  border: 0.784397px solid #cccccc;
  box-sizing: border-box;
  border-radius: 7.84397px;
}
.chatbox-textarea {
  display: flex;
  width: 80%;
  margin-left: 10px;
  /* width: 10px; */
}
.chatbox-sendbutton-msg {
  cursor: pointer;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: bold;
  font-size: 10.9816px;
  line-height: 15px;
  /* identical to box height */
  display: flex;
  min-width: 60px;
  justify-content: right;
  align-items: center;
  padding-top: 7px;
  padding-right: 14.11px;
  padding-bottom: 9.1px;
  /* background-color: red; */

  color: #39d3d0;
}
.chatbox-initial {
  /* background-color: red; */
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  width: 26.67px;
  height: 26.67px;
  display: flex;
  border-radius: 25px;

  margin-bottom: 10px;
  margin-top: 12.55px;
  margin-left: 25.89px;
  border: 0.784397px solid #2d5380;
  box-sizing: border-box;
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 9.41277px;
  line-height: 13px;
  /* identical to box height */

  text-align: center;

  /* Secondary */

  color: #2d5380;

  /* border-radius: 7.84397px; */
}
.chatboc-dropdown-number {
  margin-left: 4px;
  margin-top: -8px;

  /* margin-left: 12.55px; */
  /* margin-bottom: 10px; */
  align-items: baseline;
}
.text-header-number {
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  /* Secondary */

  color: #2d5380;
}
.css-u57fxu-singleValue {
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  /* Secondary */

  color: #2d5380 !important;
}
.user-profile-image-box {
  position: relative;
  border: 1px solid #2d5380;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  height: 34px;
  width: 34px;
  display: flex;
  border-radius: 25px;
  margin-top: 15px;
  margin-bottom: 10px;
}
.user-profile-image {
  height: 34px;
  width: 34px;
  border-radius: 25px;
}
.available {
  width: 100%;
  display: flex;
  justify-content: right;
  margin-right: 10px;
}
/* Chat box */
@font-face {
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Regular"),
    url("../../Assets/Font/segoe-ui/Segoe\ UI.woff") format("woff");
}

.start-chatting {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  font-family: "Segoe UI Regular";
}

.chatbox-textarea {
  display: flex;
  flex: 1;
}
.chatbox-textarea form {
  display: flex;
  flex: 1;
}
.chatbox-textarea form input {
  width: 100%;
}

.available svg {
  transform: translate(-12px, 6px) scale(1.3);
}
.available {
  font-family: "Segoe UI Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  width: max-content;
  margin-right: 1.4rem;
}

.smsFrom {
  position: absolute;
  right: 7px;
  top: 4.5px;
  display: flex;
  flex-direction: row;
}

.smsFrom-1 {
  margin-top: 0.6rem;
  font-family: "Segoe UI Regular";
  font-size: 14px;
  margin-right: 0.8rem;
}

.send-to-option {
  font-family: "Segoe UI Regular";
  margin-left: 0.7rem;
  font-size: 14px;
}
