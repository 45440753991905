@import url("https://fonts.cdnfonts.com/css/segoe-ui-4");
.page-container {
  max-width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  .fullscreen-page {
    font-family: Segoe UI;
    margin-top: 0.7rem;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    background-color: #fafafa;
    overflow: hidden;
    .content-section {
      margin-right: 0.7rem;
      width: 90%;
      max-width: 1100px;
      height: 90vh;
      max-height: 90vh;
      &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
      }
    }
  }
}
